<template>
  <div class="page">
     <top-bar :title="'居家养老'" :left="true"></top-bar>
    <div class="header">
      <van-search v-model="searchKey" placeholder="请输入姓名" shape="round" @search="onSearch" />
      <div class="scroll">
        <van-dropdown-menu active-color="#3583f0">
          <van-dropdown-item v-model="menuValueSex" :options="menuOptionSex" style="width: 100px" @change="menuChange('sex')" />
          <van-dropdown-item v-model="menuValueBlood" :options="menuOptionBlood" style="width: 150px" @change="menuChange('blood')" />
          <van-dropdown-item v-model="menuValuePay" :options="menuOptionPay" style="width: 100px" @change="menuChange('pay')" />
        </van-dropdown-menu>
      </div>
    </div>
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
      <div class="content" v-if="listData.length>0">
        <div class="list">
          <div class="item" v-for="(item,index) in listData" :key="index" @click="navToDetailFun(item.id)">
            <div class="top">
              <div class="text">{{item.fullName||'未知'}}</div>
              <div class="label">
                <van-tag v-for="(tag,i) in item.label" :key="i" class="tag" type="primary" :color="colorList[i].color" :text-color="colorList[i].textColor">{{tag}}</van-tag>
              </div>
            </div>
            <div class="detail">
              <div class="img">
                <div class="imgBox">
                  <img :src="item.headImg||require('@/assets/img/header.jpg')" />
                </div>
              </div>
              <div class="info">
                <div class="name">
                  {{item.name}}
                  <span class="t">/ {{item.sex==1?'男':'女'}}</span>
                </div>
                <div class="num">{{item.mobile}}</div>
                <div class="num">{{item.idNumber}}</div>
              </div>
              <div class="icon">
                <span class="iconfont icon-dianhua phone" style="color: #6da8f4"></span>
                <span class="iconfont icon-youbian" style="color: #999"></span>
              </div>
            </div>
            <div class="bottom">
              <div class="symptom" v-if="item.irritability==0">无过敏</div>
              <div class="symptom" v-if="item.irritability==1">青霉素过敏</div>
              <div class="symptom" v-if="item.irritability==2">其他过敏</div>
              <div class="pay">支付方式：{{item.paymentTypeName||'未知'}}</div>
              <div class="doctor">医生：{{item.doctor||'未知'}}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无数据" v-else />
    <!-- </van-pull-refresh> -->
    <div class="addBtn" @click="addFun">
      <img src="@/assets/img/add.png" style="width: 100%; height: 100%" />
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { Toast } from "vant";
// import { upload, getDictTree } from '@/utils/common'
import { mapState } from "vuex";
export default {
  data() {
    return {
      colorList: [{ textColor: '#f16e04', color: '#faf1ea' }, { textColor: '#3583f0', color: '#e9f0ff' }, { textColor: '#fdedee', color: '#ec453f' }, { textColor: '#39d16c', color: '#eaf7ed' },],
      searchKey: "",
      menuValueSex: "",
      menuValueBlood: "",
      menuValuePay: "",
      menuOptionSex: [
        { text: "性别", value: "" },
        { text: "男", value: 1 },
        { text: "女", value: 0 },
      ],
      menuOptionBlood: [
        { text: "血型", value: "" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "AB", value: "AB" },
        { text: "O", value: "O" },
        { text: "RH阴性", value: "RH阴性" },
      ],
      menuOptionPay: [
        { text: "支付方式", value: "" },
        { text: "城镇职工基本医疗保险", value: 0 },
        { text: "城镇居民基本医疗保险", value: 1 },
        { text: "新型农村合作医疗保险", value: 2 },
        { text: "其他", value: 3 },
      ],
      listData: [],
      pageIndex: 1,
      pageSize: 100,
      totalPage: 0,
      isLoading: false
    };
  },
  created() {
    this.getDataList();
  },
  beforeDestroy() { },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      // console.log(this.dataForm);
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqhomecare/list"),
        method: "get",
        params: this.$http.adornParams({
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.searchKey,
          sex: this.menuValueSex,
          bloodType: this.menuValueBlood,
          paymentType: this.menuValuePay,
        }),
      }).then(({ data }) => {
        this.$toast.clear();
        if (data && data.code === 0) {
          // console.log(data.page.list)
          this.isLoading = false
          this.listData = data.page.list;
        } else {
          this.isLoading = false
          Toast.fail(data.msg);
        }
      });
    },
    navToDetailFun(id) {
      this.$router.push({ name: "live-old-detail", query: { id: id } });
    },
    onSearch() {
      // console.log("val",this.searchKey)
      this.getDataList();
    },
    menuChange(type) {
      this.getDataList();
    },
    onRefresh() {
      this.getDataList()
    },
    addFun() {
      this.$router.push({ name: "live-old-detail" });
    },
  },

  components: {
    topBar,
  },
};
</script>
<style  lang="scss" scoped>
.page{
  // padding-top: 100px;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  .header {
    .scroll {
      width: 100%;
      overflow-x: scroll;
    }
  }
  .content {
    .list {
      padding: 20px 0;
      .item {
        width: 690px;
        height: 270px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto 20px;
        padding: 20px;
        .top {
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tag {
            padding: 4px 6px;
            margin-left: 20px;
            height: 40px;
          }
          .text {
            font-size: 28px;
            color: #000;
            font-weight: bold;
            .t {
              color: #999;
              font-size: 24px;
              font-weight: normal;
            }
          }
        }
        .detail {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 20px 10px;
          .img {
            width: 145px;
          }
          .imgBox {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            border: 5px solid #ededed;
            overflow: hidden;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info {
            width: 365px;
            .name {
              font-size: 28px;
              color: #000;
              .t {
                color: #999;
                font-size: 24px;
              }
            }
            .num {
              color: #b7b7b7;
              margin-top: 10px;
              font-size: 24px;
            }
          }
          .icon {
            flex: 1;
            text-align: right;
            font-size: 40px;
            .phone {
              margin-right: 40px;
            }
          }
        }
        .bottom {
          color: #6da8f4;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 22px;
          .symptom {
            width: 145px;
            text-align: center;
          }
          .pay {
            width: 365px;
          }
        }
      }
    }
  }
  .add {
    width: 110px;
    height: 110px;
    position: fixed;
    right: 30px;
    bottom: 20%;
  }
}
</style>
